import React, { useCallback, useMemo } from "react";

import classNames from "classnames";
import { toast } from "sonner";
import { Accept, useDropzone } from "react-dropzone";

import { Lot, Media } from "types";
import { PhotoProvider } from "react-photo-view";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import {
  MEDIA_MESSAGES,
  mediaVariantSelector,
} from "@/data/media/media.helpers";
import { MediaListItem } from "../media/Media.ListItem";
import { useMediaUploader } from "@/data/media/MediaUploader.Provider";
import { MediaTypes } from "@/data/media/mediaQueue";
import { useAssetsOnLot } from "@/data/media/useAssetsOnLot.hook";
import { DocumentArrowUpIcon } from "@heroicons/react/24/solid";

function MediaWranglerTab({ lot }: { lot: Lot | null }) {
  let lotAttributes = useAssetsOnLot(lot);

  if (!lot) {
    return <></>;
  }
  return (
    <>
      <div className="p-3 mt-3 h-full w-full">
        <div className="flex flex-row  gap-2  h-full">
          {lotAttributes.map((medAtt, i) => {
            // We choose not to show the main image in the media wrangler as it cant be uploaded - Only Generated we choose to do ID and not type as there might me another.
            if (medAtt.id === "mainImage") {
              return null;
            }
            return (
              <div
                key={medAtt.id}
                className="flex flex-col relative w-full rounded-md bg-white p-4  h-full"
              >
                <PhotoProvider maskOpacity={0.7}>
                  <MultipleAssets
                    lot={lot}
                    values={(medAtt.assets as Media[]) || []}
                    attributeId={medAtt.id}
                    attributeType={medAtt.type as MediaTypes}
                    allowMultiple={medAtt.type === "mediaset"}
                    accept={{
                      "image/png": [],
                      "image/jpg": [],
                      "image/jpeg": [],
                      "video/mp4": [],
                      "video/quicktime": [],
                      "image/heic": [],
                      "image/heif": [],
                    }}
                  />
                </PhotoProvider>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default MediaWranglerTab;

const MultipleAssets = ({
  values,
  attributeId,
  attributeType,
  allowMultiple,
  lot,
  accept,
}: {
  lot: Lot;
  allowMultiple: boolean;
  values: Media[];
  attributeId: string;
  attributeType: MediaTypes;
  accept: Accept | undefined;
}) => {
  const { onDropZoneDrop, reOrderMediaItems, deleteAsset, tempUploadedItems } =
    useMediaUploader();
  /**
   * Ensures we only display the media that as the same attribute type and id
   */
  const optimisticMediaItems = useMemo(() => {
    const itemInOptimistic = tempUploadedItems.get(lot?.id);
    if (!itemInOptimistic) {
      return [];
    }

    const attributeInOptimistic = itemInOptimistic[attributeType];

    if (!attributeInOptimistic) {
      return [];
    }

    return attributeInOptimistic.filter(
      (item) => item.attributeId === attributeId
    );
  }, [tempUploadedItems, lot]);

  const fileUploadID = `file-upload-${attributeId}`;

  let { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    autoFocus: false,
    noClick: true,
    multiple: allowMultiple,
    maxSize: allowMultiple ? 500 * 1024 * 1024 : 50 * 1024 * 1024, // 500MB
    onDropAccepted: (f) => {
      if (attributeType === "media" && values.length > 0) {
        toast.error(
          `${attributeType} Already Exists, remove the current one before uploading a new one`
        );
        return;
      }

      onDropZoneDrop({
        files: f,
        attributeId: attributeId,
        attributeType: attributeType,
        lotId: lot?.id,
        marketId: lot?.marketId,
        saleId: lot?.saleId,
      });
    },
    onDropRejected: (fileRejections) => {
      console.error(fileRejections);
      for (let rejection of fileRejections) {
        if (rejection.errors[0].code === "file-invalid-type") {
          toast.error(MEDIA_MESSAGES.NON_SUPPORTED_TYPE);
        } else if (rejection.errors[0].code === "file-too-large") {
          toast.error(MEDIA_MESSAGES.FILE_TOO_LARGE);
        } else {
          toast.error(rejection.errors[0].message);
        }
      }
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  // TODO :: This is jumpy as it waits for FIREBASE TO come back with data
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over) return;

      const activeItem = values.find((item) => item.url === active.id);
      const overItem = values.find((item) => item.url === over.id);

      if (!activeItem || !overItem) {
        return;
      }
      // Check if items has been competed uploaded
      const selectImageVariantOver = mediaVariantSelector(
        overItem,
        "thumbnail"
      );
      const selectImageVariantActive = mediaVariantSelector(
        activeItem,
        "thumbnail"
      );

      if (selectImageVariantOver?.isLocal) {
        toast.error(MEDIA_MESSAGES.CANT_SORT_LOCAL_IMAGE);
        return;
      }
      if (selectImageVariantActive?.isLocal) {
        toast.error(MEDIA_MESSAGES.CANT_SORT_LOCAL_IMAGE);
        return;
      }
      const activeItemIndex = values.findIndex(
        (item) => item.url === active.id
      );
      const overItemIndex = values.findIndex((item) => item.url === over.id);

      const order = arrayMove(values, activeItemIndex, overItemIndex);
      reOrderMediaItems({
        newOrder: order,
        type: attributeId as MediaTypes,
        lotId: lot?.id,
        marketId: lot?.marketId,
        saleId: lot?.saleId,
      });
    },
    [values, reOrderMediaItems, lot, attributeId]
  );

  const setAsFirstInArray = (media: Media) => {
    const activeItemIndex = values.findIndex(
      (item) => item.fileName === media.fileName
    );
    const order = arrayMove(values, activeItemIndex, 0);
    reOrderMediaItems({
      newOrder: order,
      type: attributeId as MediaTypes,
      lotId: lot?.id,
      marketId: lot?.marketId,
      saleId: lot?.saleId,
    });
  };

  const hasAnyItems = (values.length || 0) + optimisticMediaItems.length;

  return (
    <>
      <div className="flex gap-2 justify-between pb-2">
        <div className="py-2 capitalize text-md flex flex-row gap-2 items-center text-gray-700 font-semibold">
          {attributeId}
        </div>
        <label
          htmlFor={fileUploadID}
          className="flex gap-2 items-center cursor-pointer rounded-md bg-green-600 font-semibold text-white   hover:opacity-80  text-sm py-2 px-4"
        >
          <DocumentArrowUpIcon className="h-4 w-4" />
          <span>{"Upload a file"}</span>
          <input
            id={fileUploadID}
            name={fileUploadID}
            type="file"
            className="sr-only"
            {...getInputProps()}
          />
        </label>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[]}
      >
        <div
          {...getRootProps()}
          className={classNames(`
        ${
          isDragActive
            ? "opacity-95 border-2 border-martEye-400 rounded-lg"
            : "border-2 border-white"
        }
        bg-gray-200/50  gap-2 p-2   rounded-lg h-full overflow-y-scroll
        `)}
        >
          {hasAnyItems ? (
            <div
              className={`${
                allowMultiple
                  ? "grid grid-cols-2 sm:grid-cols-4"
                  : "grid grid-cols-1"
              } gap-2`}
            >
              {!!values.length && (
                <SortableContext
                  items={values.map((item) => ({ id: item.url }))}
                  strategy={rectSortingStrategy}
                >
                  {values.map((value, i) => (
                    <MediaListItem
                      displayMain={true}
                      setMainImage={setAsFirstInArray}
                      index={i}
                      disableReorder={!allowMultiple}
                      value={value}
                      key={value.url}
                      deleteAsset={deleteAsset}
                    />
                  ))}
                </SortableContext>
              )}
              {!!optimisticMediaItems.length && (
                <>
                  {optimisticMediaItems.map((value, i) => (
                    <MediaListItem
                      disableReorder={!allowMultiple}
                      setMainImage={setAsFirstInArray}
                      value={value}
                      key={value.url + i}
                      deleteAsset={deleteAsset}
                    />
                  ))}
                </>
              )}
            </div>
          ) : (
            <>
              <div className="w-full flex justify-center items-center h-full flex-1 min-h-32">
                <span className=" text-sm pr-1">
                  No asset to uploaded drag one here or{" "}
                </span>
                <label
                  htmlFor={fileUploadID}
                  className="cursor-pointer rounded-md font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500 underline text-sm"
                >
                  <span>{isDragActive ? " Drop the File" : " Upload"}</span>
                  <input
                    id={fileUploadID}
                    name={fileUploadID}
                    type="file"
                    className="sr-only"
                    {...getInputProps()}
                  />
                </label>
              </div>
            </>
          )}
        </div>
      </DndContext>
    </>
  );
};
