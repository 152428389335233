import { useMemo } from "react";
import { AttributeDefinition, Lot, Sale } from "types";

const fieldsIgnored = [
  "@eartag",
  "@eartagRaw",
  "@eartagStatus",
  "countOfItemsInGroup",
  "countOfItemsOverride",
  "countOfEartagsScanned",
];
const lotDetailFlyoutGroupToOrderHigher = ["item detail", "lot", "haulier"];

export const useLotAttributeMenuOptions = (sale: Sale, lot: Lot) => {
  const productCode = lot?.productCode;

  const menuByProductCode: string[] =
    sale?.attributeSetByProductCode[productCode] || [];

  const attributeFromFields = useMemo(() => {
    if (menuByProductCode.length === 0) {
      return [];
    }

    const fields = menuByProductCode
      .map((code) => {
        const field = sale?.attributeSet.find((field) => field.id === code);

        if (!field) {
          return null;
        }

        if (fieldsIgnored.includes(field.id)) {
          return null;
        }

        if (field.level === "item") {
          return null;
        }

        return {
          ...field,
        };
      })
      .filter(Boolean);

    return fields;
  }, [menuByProductCode, sale?.attributeSet]);

  const sortedAttributeFields = useMemo(() => {
    const attributeFields = attributeFromFields.reduce(
      (acc, field) => {
        if (!field) {
          return acc;
        }
        const cleanFlyOutGroup = cleanName(
          field.lotDetailFlyoutGroup || field.level
        );
        return {
          ...acc,
          [cleanFlyOutGroup]: [...(acc[cleanFlyOutGroup] || []), field],
        };
      },
      {} as {
        [key: string]: AttributeDefinition[];
      }
    );
    const fields = Object.keys(attributeFields);

    const sortedFields = fields.sort((a, b) => {
      const aIndex = lotDetailFlyoutGroupToOrderHigher.indexOf(a);
      const bIndex = lotDetailFlyoutGroupToOrderHigher.indexOf(b);
      return aIndex - bIndex;
    });

    return { fields: sortedFields, attributeFields };
  }, [attributeFromFields]);

  return {
    sortedAttributeFields,
    attributeFromFields,
  };
};

const cleanName = (name: string) => {
  return name.replace(/~.*/, "");
};
