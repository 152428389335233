import { mediaVariantSelector } from "@/data/media/media.helpers";
import studioDark from "../../images/studio-icon.png";
import { VideoCameraIcon } from "@heroicons/react/24/solid";
import { CSSProperties, useState } from "react";
import { Media } from "types";
import Image from "next/image";
import { Spinner } from "../Buttons";
import classNames from "classnames";

export const MediaDisplay = ({
  value,
  width = 50,
  height = 50,
  varietyPriority = "thumbnail",
  className,
  style,
  icon,
}: {
  value: Media;
  width?: number;
  height?: number;
  varietyPriority?: "small" | "medium" | "large" | "compressed" | "thumbnail";
  className?: string;
  style?: CSSProperties | undefined;
  icon?: React.ReactNode;
}) => {
  const [fallBackImage, setFallbackImage] = useState<boolean>(false);

  const isProcessing = Object.values(value.variants).filter((v) => {
    return v.isProcessing === true;
  }).length;

  let iconType = null;
  if (value?.fileType?.includes("video")) {
    iconType = <VideoCameraIcon className="h-5 w-5" />;
  }
  if (icon) {
    iconType = icon;
  }

  const selectImageVariant = mediaVariantSelector(value, varietyPriority);

  if (isProcessing) {
    if (value?.fileType?.includes("video") && selectImageVariant?.isLocal) {
      return (
        <div className="flex justify-center items-center h-full w-full">
          <div className=" flex flex-col justify-center items-center gap-2 rounded-lg relative bg-gradient-to-t from-slate-400 to-slate-50">
            {isProcessing && (
              <Spinner className="absolute w-4 -top-1 -left-1 shadow-lg  z-[999]" />
            )}
            {iconType && (
              <div className="absolute top-0 right-0 h-6 w-6 text-martEye-400 z-10 bg-white/90 rounded-tr-md rounded-bl-md flex items-center justify-center">
                {iconType}
              </div>
            )}
            <video
              style={{
                ...style,
              }}
              className="inline-block w-full h-full rounded-lg grayscale"
              controls={false}
              src={selectImageVariant?.url}
            ></video>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="relative w-full h-full">
          {isProcessing && (
            <Spinner className="absolute w-4 -top-1 -left-1 shadow-lg z-[999]" />
          )}
          {iconType && (
            <div className="absolute top-0 right-0 h-6 w-6 text-martEye-400 z-10 bg-white/90 rounded-tr-md rounded-bl-md flex items-center justify-center">
              {iconType}
            </div>
          )}
          <Image
            style={{
              ...style,
            }}
            className={`bg-gradient-to-t from-slate-400 to-slate-50     inline-block rounded-lg object-cover w-full h-full ${
              !selectImageVariant?.isLocal && "!object-none"
            }`}
            src={
              selectImageVariant?.isLocal ? selectImageVariant?.url : studioDark
            }
            alt="logo"
            width={width}
            height={height}
          />
        </div>
      </>
    );
  }

  if (!selectImageVariant) {
    return <>Image not Found</>;
  }
  return (
    <>
      <div className="relative  w-full h-full">
        {fallBackImage ? (
          <div className="relative">
            <Image
              style={{
                ...style,
              }}
              src={studioDark}
              alt="logo"
              width={width}
              height={height}
              className="inline-block size-12 rounded-lg grayscale"
            />
          </div>
        ) : (
          <>
            {iconType && (
              <div className="absolute top-0 right-0 h-6 w-6 text-martEye-400 z-10 bg-white/90 rounded-tr-md rounded-bl-md flex items-center justify-center">
                {iconType}
              </div>
            )}
            <Image
              style={{
                ...style,
              }}
              className={classNames(
                `inline-block rounded-lg w-full h-full object-cover`,
                className
              )}
              width={width}
              height={height}
              sizes="100vw"
              onError={(r) => {
                setFallbackImage(true);
              }}
              src={selectImageVariant?.url as string}
              alt={value.fileName}
            />
          </>
        )}
      </div>
    </>
  );
};
