export enum CN_URL_PARTS {
  START_MULTIPART_UPLOAD = "startMultipartUpload",
  UPLOAD_CHUNK = "uploadChunk",
  FINISH_MULTIPART_UPLOAD = "finishMultipartUpload",
  CANCEL_MULTIPART_UPLOAD = "cancelUpload",
  DELETE_UPLOAD = "deleteUpload",
}
export type ProgressCallback = {
  state: "start" | "progress" | "end";
  progress: number;
  total: number;
};

export const ALLOWED_COLS_DROP_ID = ["media"];

export const SUPPORTED_MEDIA_TYPES = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "video/mp4",
  "video/quicktime",
];

export const isMediaAllowedForId = (id: "media" | "mainImage") => {
  switch (id) {
    case "media":
      return {
        supportedTypes: [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "video/mp4",
          "video/quicktime",
        ],
        maxFiles: 10,
        id: "media",
      };
    case "mainImage":
      return {
        supportedTypes: ["image/png", "image/jpg", "image/jpeg"],
        maxFiles: 1,
        id: "mainImage",
      };

    default:
      return {
        supportedTypes: [],
        maxFiles: 0,
        id: id,
      };
  }
};

// BASE URL OF THE WORKER - THAT IS WHERE THE FILES WILL BE UPLOADED
const BASE_CF_URL = "https://media-create-worker.asig.workers.dev";
const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB - This is the default value as this is used SERVER TO SERVER WE CAN MAKE IT BUT HIGHER THAN IN APPS

type CTX = {
  filePath: string;
  file: Blob;
  headers: Headers;
  chunkSize: number;
};

type Sections = {
  partNumber: string;
  eTag: string;
};

function getFileTotalParts(
  file: Blob,
  chunkSize: number
): {
  totalParts: number;
  chunkSize: number;
} {
  const totalParts = Math.ceil(file.size / chunkSize);
  return { totalParts, chunkSize };
}

/**
 * Upload a file to Cloudflare Worker using Multipart Upload
 * On Error it will try to cancel the upload
 * @param filePath - The path to the file
 * @param file - The file to upload
 * @param overRideChunkSize - The chunk size to use - Defaults to 5MB
 * @returns {Promise<{ok: true}>}
 */
export async function multiPartUploadFile({
  filePath,
  file,
  overRideChunkSize,
  progressCallback,
}: {
  filePath: string;
  file: Blob;
  overRideChunkSize?: number;
  progressCallback?: (d: ProgressCallback) => void;
}): Promise<{ ok: true }> {
  const headers = new Headers();
  headers.append("mbtype", "raw");

  let uploadId: string = "";

  const ctx: CTX = {
    chunkSize: overRideChunkSize || CHUNK_SIZE,
    file,
    filePath,
    headers,
  };
  const mimeType = file.type;
  headers.append("mmimetype", mimeType);
  try {
    // Start Multipart Upload
    progressCallback &&
      progressCallback({ state: "start", progress: 0, total: 0 });
    uploadId = await startMultipartUpload(ctx);
    // Upload Parts
    const partsData = await uploadPart(ctx, uploadId, progressCallback);

    // Finalize
    const res = await completeMultipartUpload(ctx, uploadId, partsData).then(
      (res) => {
        progressCallback &&
          progressCallback({ state: "end", progress: 1, total: 1 });
        return res;
      }
    );
    return res;
  } catch (error) {
    console.error("Error", error);
    await cancelMultipartUpload(ctx, uploadId);
    throw new Error(error as unknown as any);
  }
}

async function startMultipartUpload(ctx: CTX): Promise<string> {
  const multiPartStartUploadUrl = new URL(
    `${BASE_CF_URL}/${CN_URL_PARTS.START_MULTIPART_UPLOAD}`
  );
  const formDataStart = new FormData();

  formDataStart.append("filePath", ctx.filePath);

  const uploadIdResponse = await fetch(multiPartStartUploadUrl, {
    method: "POST",
    headers: ctx.headers,
    body: formDataStart,
  });

  if (!uploadIdResponse.ok) {
    throw new Error("Failed to start Multipart Upload");
  }

  const multiPartUploadJson = (await uploadIdResponse.json()) as {
    data: { uploadId: string };
  };

  return multiPartUploadJson.data.uploadId;
}

async function uploadPart(
  ctx: CTX,
  uploadId: string,
  progressCallback?: (d: ProgressCallback) => void
): Promise<Sections[]> {
  const { totalParts } = getFileTotalParts(ctx.file, ctx.chunkSize);

  // UPLOAD PARTS
  const partsData = [] as Array<{ partNumber: string; eTag: string }>;
  const uploadPartUrl = new URL(`${BASE_CF_URL}/${CN_URL_PARTS.UPLOAD_CHUNK}`);
  for (let i = 0; i < totalParts; i++) {
    const start = ctx.chunkSize * i;
    const end = Math.min(ctx.file.size, start + ctx.chunkSize);
    const blob = ctx.file.slice(start, end);
    const partNumber = i + 1;

    const formData = new FormData();
    formData.append("file", blob);
    formData.append("filePath", ctx.filePath);
    formData.append("uploadId", uploadId);
    formData.append("partNumber", `${partNumber}`);
    progressCallback &&
      progressCallback({ state: "progress", progress: i, total: totalParts });
    const uploadPartResponse = await fetch(uploadPartUrl, {
      method: "POST",
      body: formData,
      headers: ctx.headers,
    });

    if (!uploadPartResponse.ok) {
      throw new Error("Failed to upload part");
    }

    const uploadPartJson = (await uploadPartResponse.json()) as {
      data: { eTag: string; partNumber: number };
    };
    const eTag = uploadPartJson.data.eTag;
    const partNumberResponse = uploadPartJson.data.partNumber;

    partsData.push({ partNumber: `${partNumberResponse}`, eTag });
  }
  return partsData;
}

async function completeMultipartUpload(
  ctx: CTX,
  uploadId: string,
  partsData: Sections[]
): Promise<{ ok: true }> {
  // FINALIZE
  const completeUploadUrl = new URL(
    `${BASE_CF_URL}/${CN_URL_PARTS.FINISH_MULTIPART_UPLOAD}`
  );

  const formDataDone = new FormData();

  formDataDone.append("uploadId", uploadId);
  formDataDone.append("filePath", ctx.filePath);
  formDataDone.append("sections", JSON.stringify(partsData));

  const completeUploadResponse = await fetch(completeUploadUrl, {
    method: "POST",
    body: formDataDone,
    headers: ctx.headers,
  });

  if (!completeUploadResponse.ok) {
    console.log("Error", completeUploadResponse);

    throw new Error("Failed to complete Multipart Upload");
  }

  return {
    ok: true,
  };
}

async function cancelMultipartUpload(
  ctx: CTX,
  uploadId: string
): Promise<void> {
  const abortUrl = new URL(
    `${BASE_CF_URL}/${CN_URL_PARTS.CANCEL_MULTIPART_UPLOAD}`
  );

  const formDataAbort = new FormData();
  formDataAbort.append("uploadId", uploadId);
  formDataAbort.append("filePath", ctx.filePath);

  const abortUploadResponse = await fetch(abortUrl, {
    headers: ctx.headers,
    method: "POST",
    body: formDataAbort,
  });
  console.log("AbortUploadResponse", abortUploadResponse);
}

export async function unlinkAssets(fileUrl: string) {
  const unlinkUrl = new URL(`${BASE_CF_URL}/${CN_URL_PARTS.DELETE_UPLOAD}`);
  const headers = new Headers();
  const formDataAbort = new FormData();
  formDataAbort.append("fileUrl", fileUrl);

  const unlinkAssets = await fetch(unlinkUrl, {
    headers: headers,
    method: "DELETE",
    body: formDataAbort,
  });

  if (!unlinkAssets.ok) {
    throw new Error("Error unlinking assets");
  }
  return unlinkAssets;
}
