import { getDisplayName } from "@/data/customerUtils";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import {
  Cog8ToothIcon,
  LightBulbIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { Customer } from "../../types";
import { ConfirmationDialoguePersonFlyoutMount } from "../ConfirmationDialogProvider";
import Flyout from "../Flyout/Flyout";
import { useFlyout } from "../Flyout/useFlyout";
import { StandardTabs } from "../StandardTabs";
import { CheckoutSheet } from "../checkout-sheet-draft/CheckoutSheet";
import { InvoiceSheetPersonFlyoutMount } from "../invoice-sheet/InvoiceSheet";
import { PersonIcon } from "./PersonIcon";
import { AccountsTab, CartTab, OverviewTab, SettingsTab } from "./Tabs";
import { useAllUninvoicedLotsBoughtAndSold } from "./useAllLotsBroughtAndSold";
import InsightsTab from "./Tabs/InsightsTab";

const flyoutUrlParam = "u";
const flyoutTabUrlParam = "ut";

export function useOpenPersonFlyout() {
  let [isFlyoutOpen, openFlyout, closeFlyout] = useFlyout([
    flyoutUrlParam,
    flyoutTabUrlParam,
  ]);

  let openPersonFlyout = useCallback(
    (
      uid: string | null,
      tab?: number | null | undefined,
      clearOthers: boolean = true
    ) => {
      openFlyout(
        uid
          ? {
              u: uid,
              ut: tab,
            }
          : null,
        clearOthers
      );
    },
    [openFlyout]
  );

  return {
    openPersonFlyout,
    personFlyoutIsOpen: isFlyoutOpen,
    closePersonFlyout: closeFlyout,
  };
}

export default function PersonFlyout() {
  let router = useRouter();
  let marketId = router.query.marketId as string;

  return (
    <Flyout
      urlParams={[flyoutUrlParam, flyoutTabUrlParam]}
      submountDialogs={[
        CheckoutSheet,
        InvoiceSheetPersonFlyoutMount,
        ConfirmationDialoguePersonFlyoutMount,
      ]}
    >
      {(urlParamValues) => {
        let uid = urlParamValues ? urlParamValues[flyoutUrlParam] : null;

        let customerLoadInfo = useStudioStream("customer-info", marketId, uid);

        if (customerLoadInfo.exists === false) {
          return (
            <Flyout.Body>
              <Flyout.Title>Account ID not found</Flyout.Title>
              <div className="divide-y divide-gray-200 bg-[#3F514C]"></div>
            </Flyout.Body>
          );
        }

        let customer = customerLoadInfo.data ?? null;

        return (
          <Flyout.Body>
            <Flyout.Title>
              <div className="flex">
                <div className="mr-2 flex-shrink-0 self-center">
                  <PersonIcon customer={customer} />
                </div>
                <div className="flex flex-col">
                  {getDisplayName(customer)}
                  <div className="flex flex-row justify-between">
                    {customer?.accountNumber && (
                      <span className="block text-sm font-light text-gray-400">
                        {customer?.accountNumber}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Flyout.Title>
            <PersonFlyoutInner
              marketId={marketId}
              uid={uid}
              customer={customer}
              activeTab={
                urlParamValues ? urlParamValues[flyoutTabUrlParam] : null
              }
            ></PersonFlyoutInner>
          </Flyout.Body>
        );
      }}
    </Flyout>
  );
}

function PersonFlyoutInner(props: {
  marketId: string;
  uid: string | null;
  customer: Customer | null;
  activeTab: string | null;
}) {
  let { marketId, uid, customer, activeTab } = props;

  // Need to check if the user has items in their cart
  let { boughtLots, soldLots, isLoading } = useAllUninvoicedLotsBoughtAndSold(
    marketId,
    uid
  );

  let cartItemCount = 0;
  if (boughtLots) {
    cartItemCount += boughtLots.length;
  }
  if (soldLots) {
    cartItemCount += soldLots.length;
  }

  const tabs = useMemo(() => {
    return [
      {
        title: "Overview",
        icon: <Cog8ToothIcon className="mr-2 -ml-1 h-4 w-4"></Cog8ToothIcon>,
        content: (
          <OverviewTab customer={customer} marketId={marketId}></OverviewTab>
        ),
      },
      {
        title: "Accounts",
        icon: <Cog8ToothIcon className="mr-2 -ml-1 h-4 w-4"></Cog8ToothIcon>,
        content: (
          <AccountsTab customer={customer} marketId={marketId}></AccountsTab>
        ),
      },
      {
        title: "Settings",
        icon: <Cog8ToothIcon className="mr-2 -ml-1 h-4 w-4"></Cog8ToothIcon>,
        content: (
          <SettingsTab customer={customer} marketId={marketId}></SettingsTab>
        ),
      },
      {
        title: "Insights",
        icon: <LightBulbIcon className="mr-2 -ml-1 h-4 w-4"></LightBulbIcon>,
        content: <InsightsTab customer={customer} marketId={marketId} />,
      },
      {
        spacer: true,
        title: "Spacer",
        content: <div></div>,
      },
      {
        title: "Cart",
        badgeCount: cartItemCount,
        icon: (
          <ShoppingCartIcon className="mr-2 -ml-1 h-4 w-4"></ShoppingCartIcon>
        ),
        content: <CartTab customer={customer} marketId={marketId}></CartTab>,
        defaultIsLoading: true,
      },
    ];
  }, [customer, marketId, cartItemCount]);

  let initialTab = 0;
  if (cartItemCount > 0) {
    initialTab = tabs
      .filter((t) => !t.spacer)
      .findIndex((t) => t.title === "Cart");
  }
  if (activeTab !== undefined && activeTab !== null) {
    initialTab = parseInt(activeTab);
  }

  return (
    <div className="relative flex-grow divide-y divide-gray-200 bg-martEye-700">
      <StandardTabs
        tabs={tabs}
        urlParam={flyoutTabUrlParam}
        initialTab={initialTab}
      ></StandardTabs>
    </div>
  );
}
