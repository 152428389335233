import React from "react";
import { PhotoView } from "react-photo-view";
import { Media } from "types";
import { mediaVariantSelector } from "@/data/media/media.helpers";
import { MediaDisplay } from "./Media.ImageDisplay";
import { Spinner } from "../Buttons";

export function MediaViewerWrapper({
  children,
  media,
}: {
  media: Media;
  children: JSX.Element;
}) {
  const selectImageVariant = mediaVariantSelector(media, "compressed");

  return (
    <>
      <PhotoView
        width={window.innerWidth}
        height={window.innerHeight}
        render={({ attrs }) => {
          if (selectImageVariant?.isProcessing) {
            return (
              <div {...attrs} className="flex justify-center items-center ">
                <div className="flex flex-col items-center justify-center h-full w-full p-10 gap-2">
                  <Spinner animating={true} className={"size-6 text-white"} />
                  <div className="text-white">Media Still Processing...</div>
                </div>
              </div>
            );
          }

          return (
            <div {...attrs} className="flex justify-center items-center">
              <div
                className="flex items-center justify-center h-full w-full p-10"
                style={{
                  height: window.innerHeight * 0.8,
                }}
              >
                {selectImageVariant?.type === "video" && (
                  <video
                    controls
                    style={{
                      width: "100%",
                      height: window.innerHeight * 0.8,
                    }}
                  >
                    <source src={selectImageVariant?.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                {selectImageVariant?.type === "image" && (
                  <MediaDisplay
                    className="!object-contain"
                    value={media}
                    varietyPriority={"large"}
                  />
                )}
              </div>
            </div>
          );
        }}
      >
        {children}
      </PhotoView>
    </>
  );
}
