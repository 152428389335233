/***
 * One stop to get a streaming set of data
 */

import { MarketInfo, PropsWithSession } from "@/pages/_app";
import {
  QueryFieldFilterConstraint,
  Timestamp,
  collection,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { Context, useContext, useEffect, useMemo, useRef } from "react";
import {
  Accessory,
  AdjustmentsConfiguration,
  AttributeDefinition,
  Customer,
  CustomerInteraction,
  Invoice,
  Lot,
  Market,
  MemberSharingConfiguration,
  Payment,
  Payout,
  Printer,
  Product,
  ProductCodeConfiguration,
  ProductConfiguration,
  Sale,
  SettingsAccessories,
  SettingsAdjustmentsConfiguration,
  SettingsGlobalAttributes,
  SettingsMarketDefaults,
  SettingsPrinters,
  SettingsTaxRates,
  TaxRate,
  Transaction,
} from "types";
import { AccessLevel } from "../AccessLevel";
import {
  DocLoadState,
  QueryLoadState,
  useFirestore,
  useLoadFirestoreDoc,
  useLoadFirestoreQuery,
} from "./firebase/useFirestore";
import { StatsAndFilters } from "./loaders/useFilteredLotData";
import { SaleCustomersContext } from "./SaleDataCustomersProvider";
import { _InternalSaleCtxs } from "./StudioSaleProvider";

export const SessionContext = React.createContext<Partial<PropsWithSession>>(
  {}
);

/***
 * 
 *  This replaces and standardises these:
 * 
export function useStudioStream("currentSale"); {
  return useContext(SaleContext).data;
}

export function useSaleProductCodes() {
  return useContext(SaleSettingsProductCodesContext);
}

export function useSaleMarketDefaults() {
  return useContext(SaleSettingsMarketDefaultsContext);
}

export function useSaleLots() {
  return useContext(SaleLotsContext);
}

 */

interface ContextConfigEntry<T> {
  context: Context<T>;
}
interface ContextConfigSelectorEntry<T, V> {
  context: Context<T>;
  selector: (value: T) => V;
}

interface LoaderConfigEntry<V> {
  loader: (...args: any[]) => V;
}

const DummyUndefinedContext = React.createContext(undefined);
const DummyUndefinedLoader = () => undefined;
const DefaultSelector = function <T>(contextValue: T) {
  return contextValue;
};

interface Config {
  market: LoaderConfigEntry<Market | null>;

  member: LoaderConfigEntry<MemberSharingConfiguration | null>;
  members: LoaderConfigEntry<MemberSharingConfiguration[]>;
  customer: LoaderConfigEntry<Customer | null>;
  "customer-interactions": LoaderConfigEntry<CustomerInteraction[]>;
  "customer-info": LoaderConfigEntry<DocLoadState<Lot>>;
  marketDefaultSettings: LoaderConfigEntry<SettingsMarketDefaults | null>;
  taxRates: LoaderConfigEntry<TaxRate[]>;
  adjustments: LoaderConfigEntry<AdjustmentsConfiguration[]>;
  productCodes: LoaderConfigEntry<ProductCodeConfiguration[]>;
  products: LoaderConfigEntry<Product[]>;
  sale: LoaderConfigEntry<Sale | null>;

  printers: LoaderConfigEntry<Printer[]>;
  accessories: LoaderConfigEntry<Accessory[]>;

  payments: LoaderConfigEntry<Payment[] | null>;
  payouts: LoaderConfigEntry<Payout[] | null>;

  invoice: LoaderConfigEntry<Invoice | null>;

  transactions: LoaderConfigEntry<QueryLoadState<Transaction>>;
  balance: LoaderConfigEntry<DocLoadState<number>>;

  "sale:sale": ContextConfigSelectorEntry<DocLoadState<Sale>, Sale | null>;
  "sale:lots-info": ContextConfigEntry<QueryLoadState<Lot>>;
  "sale:lots": ContextConfigSelectorEntry<QueryLoadState<Lot>, Lot[]>;
  "sale:lotsFiltersAndStats": ContextConfigEntry<StatsAndFilters | null>;
  "sale:marketDefaultSettings": ContextConfigSelectorEntry<
    DocLoadState<SettingsMarketDefaults>,
    SettingsMarketDefaults | null
  >;
  "sale:products": ContextConfigEntry<Product[]>;
  "sale:productCodes": ContextConfigEntry<ProductCodeConfiguration[]>;

  "sale:invoices-info": ContextConfigEntry<QueryLoadState<Invoice>>;
  "sale:invoices": ContextConfigSelectorEntry<
    QueryLoadState<Invoice>,
    Invoice[]
  >;
  "sale:customers": ContextConfigEntry<Customer[]>;

  // From the user's session
  "session:marketId": ContextConfigSelectorEntry<
    Partial<PropsWithSession>,
    string | null
  >;
  "session:market": ContextConfigSelectorEntry<
    Partial<PropsWithSession>,
    MarketInfo | null
  >;
  // all markets the user has access to
  "session:markets": ContextConfigSelectorEntry<
    Partial<PropsWithSession>,
    MarketInfo[] | null
  >;
  "session:accessLevel": ContextConfigSelectorEntry<
    Partial<PropsWithSession>,
    AccessLevel | null
  >;

  globalAttributes: LoaderConfigEntry<AttributeDefinition[] | null>;
}

const config: Config = {
  market: {
    loader: (marketId?: string) => {
      let path = marketId ? `markets/${marketId}` : null;
      let loadInfo = useLoadFirestoreDoc<Market>(path, {
        idField: "id",
      });
      return loadInfo.data;
    },
  },

  globalAttributes: {
    loader: () => {
      let path = `/settings/attributes`;
      let loadInfo = useLoadFirestoreDoc<SettingsGlobalAttributes>(path, {
        idField: "id",
      });

      if (!loadInfo.data) {
        return null;
      }

      return Object.values(loadInfo.data);
    },
  },

  member: {
    loader: (marketId?: string, memberId?: string) => {
      let path =
        marketId && memberId ? `markets/${marketId}/members/${memberId}` : null;
      let loadInfo = useLoadFirestoreDoc<MemberSharingConfiguration>(path, {
        idField: "uid",
      });
      return loadInfo.data;
    },
  },
  members: {
    loader: (marketId?: string) => {
      let firestore = useFirestore();
      let path = marketId ? `markets/${marketId}/members` : null;
      let q = useMemo(() => {
        return path ? query(collection(firestore, path)) : null;
      }, [path]);

      let loadInfo = useLoadFirestoreQuery<MemberSharingConfiguration>(q, {
        idField: "uid",
      });
      return loadInfo.data;
    },
  },

  customer: {
    loader: (marketId?: string | null, customerId?: string | null) => {
      let path =
        marketId && customerId
          ? `markets/${marketId}/customers/${customerId}`
          : null;
      let loadInfo = useLoadFirestoreDoc<Customer>(path, {
        idField: "id",
      });
      return loadInfo.data;
    },
  },

  "customer-info": {
    loader: (marketId?: string | null, customerId?: string | null) => {
      let path =
        marketId && customerId
          ? `markets/${marketId}/customers/${customerId}`
          : null;
      let loadInfo = useLoadFirestoreDoc<Lot>(path, {
        idField: "id",
      });
      return loadInfo;
    },
  },

  "customer-interactions": {
    loader: (marketId?: string | null, customerId?: string | null) => {
      let firestore = useFirestore();

      let path = marketId ? `markets/${marketId}/interactions` : null;
      let q = useMemo(() => {
        if (!path) {
          return null;
        }
        let parts = [
          customerId && where("customerId", "==", customerId),
          orderBy("createdAt", "desc"),
        ];
        return query(
          collection(firestore, path),
          ...(parts.filter(Boolean) as QueryFieldFilterConstraint[])
        );
      }, [path, customerId, firestore]);

      let loadInfo = useLoadFirestoreQuery<CustomerInteraction>(q, {
        idField: "id",
      });
      return loadInfo.data;
    },
  },

  marketDefaultSettings: {
    loader: (marketId?: string | null) => {
      let defaults = useLoadFirestoreDoc<SettingsMarketDefaults>(
        marketId ? `markets/${marketId}/settings/defaults` : null
      );
      return defaults.data;
    },
  },

  taxRates: {
    loader: (marketId: string | null | undefined) => {
      let taxRateSettings = useLoadFirestoreDoc<SettingsTaxRates>(
        marketId ? `markets/${marketId}/settings/taxRates` : null
      );
      let taxRates = useMemo(() => {
        return Object.values(taxRateSettings.data ?? {}).sort((a, b) =>
          a.id.localeCompare(b.id)
        );
      }, [taxRateSettings]);

      return taxRates;
    },
  },

  printers: {
    loader: (marketId: string | null | undefined) => {
      let printersSettings = useLoadFirestoreDoc<SettingsPrinters>(
        marketId ? `markets/${marketId}/settings/printers` : null
      );
      let printers = useMemo(() => {
        return Object.values(printersSettings.data ?? {}).sort((a, b) =>
          a.id.localeCompare(b.id)
        );
      }, [printersSettings]);

      return printers;
    },
  },

  accessories: {
    loader: (marketId: string | null | undefined) => {
      let accessoriesSettings = useLoadFirestoreDoc<SettingsAccessories>(
        marketId ? `markets/${marketId}/settings/telnetConnections` : null
      );
      let printers = useMemo(() => {
        return Object.values(accessoriesSettings.data ?? {}).sort((a, b) =>
          a.deviceId.localeCompare(b.deviceId)
        );
      }, [accessoriesSettings]);

      return printers;
    },
  },

  adjustments: {
    loader: (marketId: string | null | undefined) => {
      let adjustmentSettings =
        useLoadFirestoreDoc<SettingsAdjustmentsConfiguration>(
          marketId ? `markets/${marketId}/settings/adjustments` : null
        );

      let adjustments = useMemo(() => {
        return Object.values(adjustmentSettings.data ?? {}).sort((a, b) =>
          a.id.localeCompare(b.id)
        );
      }, [adjustmentSettings]);
      return adjustments;
    },
  },

  productCodes: {
    loader: (marketId?: string | null) => {
      let codes = useLoadFirestoreDoc<ProductCodeConfiguration[]>(
        marketId ? `markets/${marketId}/settings/productCodes` : null
      );
      return useMemo(() => {
        if (codes.data === null) {
          return [];
        }
        return Object.values(codes.data).sort((a, b) =>
          a.code.localeCompare(b.code)
        );
      }, [codes]);
    },
  },

  products: {
    loader: (marketId?: string | null) => {
      let productConfig = useLoadFirestoreDoc<ProductConfiguration>(
        marketId ? `markets/${marketId}/settings/products` : null
      );
      return useMemo(() => {
        return Object.values(productConfig.data ?? {}).sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }, [productConfig]);
    },
  },

  sale: {
    loader: (marketId?: string | null, saleId?: string | null) => {
      let sale = useLoadFirestoreDoc<Sale>(
        marketId && saleId ? `markets/${marketId}/sales/${saleId}` : null
      );
      return sale.data;
    },
  },

  invoice: {
    loader: (marketId?: string | null, invoiceId?: string | null) => {
      let invoice = useLoadFirestoreDoc<Invoice>(
        marketId && invoiceId
          ? `markets/${marketId}/invoices/${invoiceId}`
          : null
      );
      return invoice.data;
    },
  },

  payments: {
    loader: (
      marketId: string | null | undefined,
      queryParams: {
        customerUid?: string | null | undefined;
        from?: Timestamp | null | undefined;
        to?: Timestamp | null | undefined;

        invoiceIds?: string[] | null | undefined;
      }
    ) => {
      let firestore = useFirestore();
      let { customerUid, from, to, invoiceIds } = queryParams;

      let hasParams = marketId && ((customerUid && from && to) || invoiceIds);
      let path = hasParams ? `markets/${marketId}/payments` : null;
      let q = useMemo(() => {
        if (!path) {
          return null;
        }

        let parts = [
          customerUid && where("customerId", "==", customerUid),
          to && where("transactionDate", "<=", to),
          from && where("transactionDate", ">=", from),
          invoiceIds && where("invoiceIds", "array-contains-any", invoiceIds),
        ];

        return query(
          collection(firestore, path),
          ...(parts.filter(Boolean) as QueryFieldFilterConstraint[])
        );
      }, [
        path,
        customerUid,
        to?.toMillis(),
        from?.toMillis(),
        invoiceIds?.sort().join(","),
        firestore,
      ]);

      let loadInfo = useLoadFirestoreQuery<Payment>(q, {
        idField: "id",
      });
      return loadInfo.data;
    },
  },

  payouts: {
    loader: (
      marketId: string | null | undefined,
      queryParams: {
        customerUid?: string | null | undefined;
        from?: Timestamp | null | undefined;
        to?: Timestamp | null | undefined;

        invoiceIds?: string[] | null | undefined;
      }
    ) => {
      let firestore = useFirestore();
      let { customerUid, from, to, invoiceIds } = queryParams;

      let hasParams = marketId && ((customerUid && from && to) || invoiceIds);
      let path = hasParams ? `markets/${marketId}/payouts` : null;

      let q = useMemo(() => {
        if (!path) {
          return null;
        }
        let parts = [
          customerUid && where("customerId", "==", customerUid),
          to && where("transactionDate", "<=", to),
          from && where("transactionDate", ">=", from),
          invoiceIds && where("invoiceIds", "array-contains-any", invoiceIds),
        ];

        return query(
          collection(firestore, path),
          ...(parts.filter(Boolean) as QueryFieldFilterConstraint[])
        );
      }, [
        path,
        customerUid,
        to?.toMillis(),
        from?.toMillis(),
        invoiceIds?.sort().join(","),
        firestore,
      ]);

      let loadInfo = useLoadFirestoreQuery<Payout>(q, {
        idField: "id",
      });
      return loadInfo.data;
    },
  },

  // fetch the ledger transactions by account, ordered so the latest is first
  transactions: {
    loader: (
      marketId: string | null | undefined,
      params: {
        account: string | null | undefined;
        count?: number; // default 1
      } | null
    ) => {
      let firestore = useFirestore();

      let { account, count } = params || {};

      let q = useMemo(() => {
        if (!account) {
          return null;
        }

        if (count === undefined) {
          count = 1;
        }

        count = Math.min(count, 100);

        return query(
          collection(firestore, `markets/${marketId}/ledger`),
          where("account", "==", account),
          orderBy("index", "desc"),
          limit(count)
        );
      }, [marketId, account, count, firestore]);

      let loadInfo = useLoadFirestoreQuery<Transaction>(q, {
        idField: "id",
      });

      return loadInfo;
    },
  },

  // Fetch the current balance for an account on the ledger
  balance: {
    loader: (
      marketId: string | null | undefined,
      params: {
        account: string | null | undefined;
      } | null
    ) => {
      let firestore = useFirestore();

      let { account } = params || {};

      let q = useMemo(() => {
        if (!account) {
          return null;
        }

        return query(
          collection(firestore, `markets/${marketId}/ledger`),
          where("account", "==", account),
          orderBy("index", "desc"),
          limit(1)
        );
      }, [marketId, account, firestore]);

      let loadInfo = useLoadFirestoreQuery<Transaction>(q, {
        idField: "id",
      });

      return useMemo(() => {
        let d: DocLoadState<number> = {
          data:
            loadInfo.data?.length > 0
              ? loadInfo.data[0].currentBalanceInCents
              : null,
          loading: loadInfo.loading,
          error: loadInfo.error,
          exists: loadInfo.data?.length > 0,
        };
        return d;
      }, [loadInfo]);
    },
  },

  // These are loaded in by the StudioSaleProvider
  "sale:sale": {
    context: _InternalSaleCtxs.saleInfo,
    selector: (saleInfo) => saleInfo.data,
  },
  "sale:lots-info": {
    context: _InternalSaleCtxs.lotsInfo,
  },
  "sale:lots": {
    context: _InternalSaleCtxs.lotsInfo,
    selector: (lotsInfo) => lotsInfo.data,
  },
  "sale:lotsFiltersAndStats": {
    context: _InternalSaleCtxs.lotsFiltersAndStats,
  },
  "sale:marketDefaultSettings": {
    context: _InternalSaleCtxs.marketDefaultSettingsInfo,
    selector: (marketDefaultSettingsInfo) => marketDefaultSettingsInfo.data,
  },
  "sale:products": {
    context: _InternalSaleCtxs.products,
  },
  "sale:productCodes": {
    context: _InternalSaleCtxs.saleProductCodes,
  },
  "sale:invoices-info": {
    context: _InternalSaleCtxs.invoicesInfo,
  },
  "sale:invoices": {
    context: _InternalSaleCtxs.invoicesInfo,
    selector: (invoicesInfo) => invoicesInfo.data,
  },
  "sale:customers": {
    context: SaleCustomersContext,
  },
  // / StudioSaleProvider

  // Session
  "session:marketId": {
    context: SessionContext,
    selector: (session) => session?.market?.id ?? null,
  },

  "session:market": {
    context: SessionContext,
    selector: (session) => session?.market ?? null,
  },
  "session:markets": {
    context: SessionContext,
    selector: (session) => session?.markets ?? null,
  },

  "session:accessLevel": {
    context: SessionContext,
    selector: (session) => session?.accessLevel ?? null,
  },
  // /Session
};

// Overload signatures for the useStudioStream function
export function useStudioStream(
  type: "market",
  marketId: string | null
): Market | null;
export function useStudioStream(
  type: "globalAttributes"
): AttributeDefinition[] | null;
export function useStudioStream(
  type: "member",
  marketId: string,
  uid: string
): MemberSharingConfiguration | null;
export function useStudioStream(
  type: "members",
  marketId: string | null | undefined
): MemberSharingConfiguration[];
export function useStudioStream(
  type: "customer",
  marketId: string | null | undefined,
  uid: string | null | undefined
): Customer | null;
export function useStudioStream(
  type: "customer-info",
  marketId: string | null | undefined,
  uid: string | null | undefined
): DocLoadState<Customer>;
export function useStudioStream(
  type: "customer-interactions",
  marketId: string | null | undefined,
  customerId: string | null | undefined
): CustomerInteraction[];
export function useStudioStream(
  type: "marketDefaultSettings",
  marketId: string | null | undefined
): SettingsMarketDefaults | null;
export function useStudioStream(
  type: "taxRates",
  marketId: string | null | undefined
): TaxRate[];
export function useStudioStream(
  type: "printers",
  marketId: string | null | undefined
): Printer[];
export function useStudioStream(
  type: "accessories",
  marketId: string | null | undefined
): Accessory[];
export function useStudioStream(
  type: "adjustments",
  marketId: string | null | undefined
): AdjustmentsConfiguration[];
export function useStudioStream(
  type: "productCodes",
  marketId: string | null | undefined
): ProductCodeConfiguration[];
export function useStudioStream(
  type: "products",
  marketId: string | null | undefined
): Product[];
export function useStudioStream(
  type: "sale",
  marketId: string | null | undefined,
  saleId: string | null | undefined
): Sale | null;
export function useStudioStream(
  type: "invoice",
  marketId: string | null | undefined,
  invoiceId: string | null | undefined
): Invoice | null;

export function useStudioStream(
  type: "payments",
  marketId: string | null | undefined,
  query: {
    customerUid?: string | null | undefined;
    from?: Timestamp | null | undefined;
    to?: Timestamp | null | undefined;
    invoiceIds?: string[] | null | undefined;
  }
): Payment[];
export function useStudioStream(
  type: "payouts",
  marketId: string | null | undefined,
  query: {
    customerUid?: string | null | undefined;
    from?: Timestamp | null | undefined;
    to?: Timestamp | null | undefined;
    invoiceIds?: string[] | null | undefined;
  }
): Payout[];
export function useStudioStream(
  type: "transactions",
  marketId: string | null | undefined,
  query: {
    account: string | null | undefined;
    count?: number;
  } | null
): QueryLoadState<Transaction>;
export function useStudioStream(
  type: "balance",
  marketId: string | null | undefined,
  query: {
    account: string | null | undefined;
  } | null
): DocLoadState<number>;

// TODO. We need a way to list all the transactions for a customer. e.g profit
// export function useStudioStream(
//   type: "otherTransactions",
//   marketId: string | null | undefined,
//   customerUid: string | null | undefined,
//   from: Timestamp | null | undefined,
//   to: Timestamp | null | undefined
// ): [];

// sale
export function useStudioStream(type: "sale:sale"): Sale | null;
export function useStudioStream(type: "sale:lots-info"): QueryLoadState<Lot>;
export function useStudioStream(type: "sale:lots"): Lot[];
export function useStudioStream(
  type: "sale:lotsFiltersAndStats"
): StatsAndFilters | null;
export function useStudioStream(
  type: "sale:marketDefaultSettings"
): SettingsMarketDefaults | null;
export function useStudioStream(type: "sale:products"): Product[];
export function useStudioStream(
  type: "sale:productCodes"
): ProductCodeConfiguration[];
export function useStudioStream(
  type: "sale:invoices-info"
): QueryLoadState<Invoice>;
export function useStudioStream(type: "sale:invoices"): Invoice[];
export function useStudioStream(type: "sale:customers"): Customer[];

export function useStudioStream(type: "session:marketId"): string | null;
export function useStudioStream(type: "session:market"): MarketInfo | null;
export function useStudioStream(type: "session:markets"): MarketInfo[] | null;
export function useStudioStream(
  type: "session:accessLevel"
): AccessLevel | null;

// Implement the useStudioStream function
export function useStudioStream(Atype: keyof Config, ...args: any[]): any {
  // once the type is set we can't let the user change it otherwise it could break the rules for hooks
  let lockedTypeRef = useRef(Atype);
  let _type = lockedTypeRef.current;

  useEffect(() => {
    if (Atype !== _type) {
      throw new Error(
        `Do not change the type of useStudioStream. It was set to ${_type} and you tried to change it to ${Atype}.`
      );
    }
  }, [Atype, _type]);

  const entry = config[_type];

  let hasContext = "context" in entry;
  let hasLoader = "loader" in entry;

  let context = "context" in entry ? entry.context : DummyUndefinedContext;
  let loader =
    "loader" in entry
      ? entry.loader ?? DummyUndefinedLoader
      : DummyUndefinedLoader;

  // Handle the context case
  let contextValue = useContext(context as any);

  // Handle the loader case with arguments
  let loaderValue = loader(...args);

  if (hasContext && hasLoader) {
    throw new Error(
      `Cannot have both context and loader defined for type ${_type}`
    );
  }
  if (!hasContext && !hasLoader) {
    throw new Error(
      `Must have either context or loader defined for type ${_type}`
    );
  }

  let selector = (
    "selector" in entry ? entry.selector ?? DefaultSelector : DefaultSelector
  ) as (value: any) => any;

  let value = "context" in entry ? selector(contextValue) : loaderValue;

  return value;
}

// Usage

// let currentSale = useStudioStream("sale:sale");
// let saleLots = useStudioStream("sale:lots");
// let market = useStudioStream("market");
