import { toShortCustomerDetails } from "@/data/customerUtils";
import { FieldValue, deleteField } from "firebase/firestore";
import { Customer, ShortCustomerDetails } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";
import { getOrLoadCustomer } from "./getOrLoadCustomer";

function simpleCacheWithExpiry() {
  let cache: { [key: string]: { value: any; expiry: number } } = {};

  let reset = () => {
    cache = {};
  };

  let cleanUpTimeout = null;

  return {
    get: (key: string) => {
      let entry = cache[key];
      if (entry) {
        if (entry.expiry > Date.now()) {
          return entry.value;
        } else {
          delete cache[key];
        }
      }

      return null;
    },
    set: (key: string, value: any, ttl: number) => {
      cache[key] = { value, expiry: Date.now() + ttl };

      // set 1 sec after the last expiry
      cleanUpTimeout = setTimeout(reset, ttl + 1000);
    },
  };
}

let simpleCache: ReturnType<typeof simpleCacheWithExpiry> | null = null;

export async function createShortCustomerDetails(
  ctx: FullSaleContext,
  customerId: string | null | FieldValue
): Promise<ShortCustomerDetails> {
  let { marketId, firestore } = ctx;

  if (!simpleCache) {
    simpleCache = simpleCacheWithExpiry();
  }

  if (!customerId || deleteField().isEqual(customerId as FieldValue)) {
    return {
      isSet: false,
    };
  }

  let cached = simpleCache.get(customerId as string);
  if (cached) {
    return cached;
  }

  let customer: Customer = await getOrLoadCustomer(ctx, customerId as string);

  let result = toShortCustomerDetails(customer);

  // cache for 2 minutes
  simpleCache.set(customerId as string, result, 2 * 60 * 1000);

  return result;
}
