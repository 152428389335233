import {
  Firestore,
  Timestamp,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { autoId } from "@/data/ids";
/**
|--------------------------------------------------
| Create Customer Interaction
|--------------------------------------------------
*/
async function createCustomerInteraction(
  firestore: Firestore,
  currentUid: string | null,
  marketId: string | null,
  interaction: {
    note: string;
    customerId: string;
  }
) {
  const docId = autoId();

  let docRef = doc(firestore, `markets/${marketId}/interactions/${docId}`);

  await setDoc(docRef, {
    ...interaction,
    id: docId,
    marketId: marketId || "",
    interactionDate: Timestamp.now(),
    createdAt: Timestamp.now(),
    createdBy: currentUid || "",
    updatedAt: Timestamp.now(),
    updatedBy: currentUid || "",
  });
}

/**
|--------------------------------------------------
| Update Customer Interaction
|--------------------------------------------------
*/
async function updateCustomerInteraction(
  firestore: Firestore,
  currentUid: string | null,
  marketId: string | null,
  interaction: {
    id: string;
    note: string;
    customerId: string;
  }
) {
  let interactionRef = doc(
    firestore,
    `markets/${marketId}/interactions/${interaction.id}`
  );

  await updateDoc(interactionRef, {
    note: interaction.note,
    updatedAt: Timestamp.now(),
    updatedBy: currentUid || "",
  });
}

/**
|--------------------------------------------------
| Delete Customer Interaction
|--------------------------------------------------
*/
async function deleteCustomerInteraction(
  firestore: Firestore,
  currentUid: string | null,
  marketId: string | null,
  interaction: {
    id: string;
  }
) {
  let interactionRef = doc(
    firestore,
    `markets/${marketId}/interactions/${interaction.id}`
  );
  await deleteDoc(interactionRef);
}

export {
  createCustomerInteraction,
  updateCustomerInteraction,
  deleteCustomerInteraction,
};
