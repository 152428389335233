import { CompactSelection, Item } from "@glideapps/glide-data-grid";
import dynamic from "next/dynamic";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMeasure } from "react-use";
import { Lot, LotItem, LotIssue } from "../../types";

// Split Lots
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { SplitItems } from "../split-lot-dialog/SplitQuantity.Dialog";
import { ActionMenu, GridActions } from "../datagrid/grid.actions.menu";
import { useDataGridSelection } from "../datagrid/useDataGridSelection";
import useStudio from "@/data/studio-react/useStudio";
import { toast } from "sonner";
import { ScissorsIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useDataGridContextMenu } from "../datagrid/useDataGridContextMenu";
import { useItemsGridColumns } from "./ItemGridColumns";

const ItemsDataGrid = dynamic(() => import("./ItemsDataGrid"), {
  ssr: false,
});

interface ItemsTabProps {
  lot: Lot | null;
  issues?: LotIssue[][];
}

export function ItemsTab(props: ItemsTabProps) {
  let { deleteLotItems } = useStudio();

  const [lotToSplit, setLotToSplit] = useState<Lot | null>(null);
  let [items, setItems] = useState<LotItem[]>([]);

  let sale = useStudioStream("sale:sale");
  const allLots = useStudioStream("sale:lots");
  let l = props.lot;
  let [ref, { width, height }] = useMeasure();

  const itemGridColumns = useItemsGridColumns(sale);
  let gridSelection = useDataGridSelection();

  useEffect(() => {
    if (!l) {
      return;
    }
    let i = getItems(l);
    setItems(i);
  }, [l]);

  const preSelectedLotItems = useMemo(() => {
    let selectedItemsInGrid = gridSelection.gridSelection.rows.toArray();
    return selectedItemsInGrid.map((i) => items[i]);
  }, [gridSelection.gridSelection.rows, items]);

  const onDeleteSelectedItems = useCallback(
    (itemsRowNumber?: number[]) => {
      if (!l) {
        return;
      }
      let selectedItemsInGrid = gridSelection.gridSelection.rows.toArray();

      if (itemsRowNumber) {
        selectedItemsInGrid = itemsRowNumber;
      }

      const itemsToDeleteIds = selectedItemsInGrid.map((i) => items[i].id);

      if (itemsToDeleteIds.length === 0) {
        toast.info("No items selected to delete");
        return;
      }
      deleteLotItems(l, itemsToDeleteIds)
        .then(() => {
          toast.success("Items deleted");
        })
        .catch((e) => {
          toast.error(e.message);
        });
    },
    [l, gridSelection, deleteLotItems, items]
  );

  const contextMenuDelete = useCallback(
    (cell: Item) => {
      // Delete the Selected Rows
      if (gridSelection.gridSelection.rows.length) {
        return onDeleteSelectedItems();
      }
      // We Select the Row and then call the Normal Delete
      gridSelection.gridSelection.current?.cell[1];
      gridSelection.onGridSelectionChange({
        rows: CompactSelection.empty().add(cell[1]),
        columns: CompactSelection.empty(),
      });
      return onDeleteSelectedItems([cell[1]]);
    },
    [gridSelection, onDeleteSelectedItems]
  );

  let { onCellContextMenu, MenuComponent } = useDataGridContextMenu(
    undefined,
    undefined,
    undefined,
    undefined,
    contextMenuDelete
  );

  if (!sale) {
    return null;
  }
  const actions: ActionMenu[] = [
    {
      active: true,
      title: "Split | Reassign",
      icon: <ScissorsIcon className="h-4 w-4" aria-hidden="true" />,
      action: () => {
        setLotToSplit(props.lot);
      },
      section: "Quick Actions",
    },
    {
      active: true,
      icon: <TrashIcon className="h-4 w-4" aria-hidden="true" />,
      title: "Delete",
      action: () => {
        onDeleteSelectedItems();
      },
      className: "!text-danger-400 !hover:text-red-500",
      section: "Danger",
    },
  ];
  return (
    <>
      <div className="h-full p-3">
        <div className=" w-full p-2 flex justify-end">
          <GridActions options={actions} />
        </div>
        <div className="relative h-[94%] overflow-hidden shadow sm:rounded-md bg-white">
          <div
            className="absolute inset-0"
            ref={ref as any as React.RefObject<HTMLDivElement>}
          >
            {l && (
              <SplitItems
                preSelectedLotItems={preSelectedLotItems}
                selectLot={lotToSplit}
                showButton={false}
                allLots={allLots}
                disabled={false}
                lot={l}
                onClose={() => {
                  setLotToSplit(null);
                }}
              />
            )}
            {l && (
              <ItemsDataGrid
                lot={l}
                items={items}
                width={width}
                height={height}
                selection={gridSelection}
                onCellContextMenu={onCellContextMenu}
                itemGridColumns={itemGridColumns}
              />
            )}
            <MenuComponent />
          </div>
        </div>
      </div>
    </>
  );
}

function getItems(lotOrItems: Lot | { [id: string]: LotItem }) {
  if (!lotOrItems) {
    return [];
  }

  let items: { [id: string]: LotItem };
  if ("id" in lotOrItems) {
    items = (lotOrItems as Lot).itemMap;
  } else {
    items = lotOrItems;
  }

  // old bad data
  if (Array.isArray(items)) {
    items = {};
  }

  return Object.keys(items || {})
    .map((i) => items[i])
    .sort((a, b) => a.index - b.index);
}
