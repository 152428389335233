import React, { useMemo, useState } from "react";
import { Customer, CustomerInteraction } from "../../../types";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { formatDate } from "@/data/attribute-definitions/funcs/getDisplayValue";
import { PlusIcon } from "@heroicons/react/24/outline";
import useStudio from "@/data/studio-react/useStudio";
import { toast } from "sonner";
import { useShowDialog } from "@/components/ConfirmationDialogProvider";

function InsightsTab({
  customer,
  marketId,
}: {
  customer: Customer | null;
  marketId: string;
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const interactions = useStudioStream(
    "customer-interactions",
    marketId,
    customer?.id
  );

  const tabs = useMemo(() => {
    return interactions.map((interaction) => {
      return {
        tab: <InterActionTab interaction={interaction} />,
        crud: (
          <InteractionsCRUDTab
            interaction={interaction}
            customerId={customer?.id || ""}
            onDeleteCB={() => {
              setSelectedIndex(0);
            }}
          />
        ),
      };
    });
  }, [interactions]);

  return (
    <>
      <TabGroup
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        className=" gap-4 p-3 grid grid-cols-6 h-full "
      >
        <TabList className="overflow-y-auto col-span-2 flex flex-col">
          <Tab className="sticky top-0 data-[hover]:underline py-4 rounded-lg p-2 bg-martEye-400 hover:opacity-80 data-[selected]:bg-martEye-400/60">
            <div className="flex items-center gap-2 justify-center font-medium text-white ">
              <PlusIcon className="h-6 w-6 text-white"></PlusIcon>
              Add Insight
            </div>
          </Tab>
          {tabs.map((tab) => (
            <div className="flex flex-col pt-2">
              <Tab className="data-[selected]:bg-white data-[selected]:text-martEye-400 data-[hover]:underline py-4 rounded-lg p-2 ">
                {tab.tab}
              </Tab>
              <div className=" text-martEye-500 border-b border-martEye-200"></div>
            </div>
          ))}
        </TabList>
        <TabPanels className="col-span-4 bg-white rounded-md p-4 overflow-y-auto ">
          <TabPanel className="h-full">
            <InteractionsCRUDTab
              interaction={null}
              customerId={customer?.id || ""}
              onDeleteCB={() => {
                setSelectedIndex(0);
              }}
            />
          </TabPanel>
          {tabs.map((tab) => (
            <TabPanel className="h-full">{tab.crud}</TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </>
  );
}

export default InsightsTab;

const InterActionTab = ({
  interaction,
}: {
  interaction: CustomerInteraction;
}) => {
  const MAX_LENGTH = 60;
  const limitedText = interaction.note.slice(0, MAX_LENGTH);

  return (
    <div className="flex flex-col gap-2 text-left h-[72px] justify-between">
      <div className="text-md text-left font-medium text-martEye-500 overflow-hidden">
        {limitedText}
        {interaction.note.length > MAX_LENGTH && "..."}
      </div>
      <span className="text-xs text-martEye-500">
        {formatDate(interaction?.createdAt.toDate() || new Date())}
      </span>
    </div>
  );
};

const InteractionsCRUDTab = ({
  interaction,
  customerId,
  onDeleteCB,
}: {
  interaction: CustomerInteraction | null;
  customerId: string;
  onDeleteCB: () => void;
}) => {
  let { showDialog } = useShowDialog();
  const CUDType = interaction ? "edit" : "new";
  const [note, setNote] = useState(interaction?.note || "");
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const {
    createCustomerInteraction,
    updateCustomerInteraction,
    deleteCustomerInteraction,
  } = useStudio();

  const handleSave = async () => {
    try {
      let n = note.trim();

      if (n.length === 0) {
        toast.error("Note cannot be empty");
        return;
      }

      if (CUDType === "new") {
        createCustomerInteraction({
          note: n,
          customerId: customerId || "",
        });
        setNote("");
      } else {
        if (!interaction) {
          console.error("Interaction not found");
          return;
        }
        updateCustomerInteraction({
          id: interaction.id,
          note: n,
          customerId: customerId,
        });
      }
      setHasBeenEdited(false);
    } catch (error) {
      toast.error("Error saving interaction");
      console.error("Error saving interaction", error);
    }
  };

  const handleDelete = () => {
    if (CUDType === "new") {
      setNote("");
      setHasBeenEdited(false);
      return;
    }

    showDialog(
      "Delete Insight",
      "This will delete the insight permanently. Are you sure?",
      "Delete",
      () => {
        deleteCustomerInteraction({
          id: interaction?.id || "",
        });
        onDeleteCB();
      },
      "Cancel",
      () => {},
      "danger"
    );
  };

  return (
    <div className="h-full  grow flex flex-col">
      <div className="flex items-center justify-between border-b border-martEye-400 pb-4">
        <div className="flex flex-row gap-2 items-center">
          <div className="text-md font-medium text-martEye-500">
            {formatDate(interaction?.interactionDate.toDate() || new Date())}
          </div>
          {CUDType === "new" && (
            <div className="text-xs font-medium text-danger-400 uppercase border border-danger-400 rounded-md px-2 py-1">
              {CUDType}
            </div>
          )}
        </div>
        <div className="flex items-center gap-4 h-4">
          {hasBeenEdited && (
            <button
              className="flex items-center gap-2 text-md font-medium text-martEye-500 hover:underline"
              onClick={handleSave}
            >
              Save
            </button>
          )}

          {(hasBeenEdited || CUDType === "edit") && (
            <button
              onClick={() => {
                handleDelete();
              }}
              className="rounded-lg bg-gray-100 p-1 text-danger-400 shadow-sm   focus-visible:outline-offset-2  hover:underline px-2 hover:bg-gray-200"
            >
              <div className="flex items-center gap-2 ">Delete</div>
            </button>
          )}
        </div>
      </div>

      <div className="flex  grow py-2">
        <textarea
          autoFocus={true}
          placeholder="Start typing..."
          className="w-full rounded-md p-2 grow border-0 focus:outline-none text-martEye-500 placeholder:text-martEye-500/80 focus:placeholder:text-martEye-500/60 focus:ring-0"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
            setHasBeenEdited(true);
          }}
        />
      </div>
    </div>
  );
};
