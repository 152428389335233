import { EyeIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { Button } from "../Buttons";
import { CSS } from "@dnd-kit/utilities";
import { Media } from "types";
import { mediaVariantSelector } from "@/data/media/media.helpers";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { MediaDisplay } from "./Media.ImageDisplay";
import { MediaViewerWrapper } from "./Media.Viewer";
import { motion } from "framer-motion";

const contextMenuMotion = {
  rest: { opacity: 0, ease: "easeOut", duration: 0.2, type: "tween" },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeIn",
    },
  },
};

export const MediaListItem = ({
  value,
  disableReorder,
  deleteAsset,
  displayMain,
  index,
  setMainImage,
}: {
  value: Media;
  disableReorder: boolean;
  deleteAsset: (filePath: string) => Promise<void>;
  displayMain?: boolean;
  index?: number;
  setMainImage?: (media: Media) => void;
}) => {
  const selectImageVariant = mediaVariantSelector(value, "compressed");

  const [isLoading, setIsLoading] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: value.url,
    disabled: disableReorder || !!selectImageVariant?.isLocal || isLoading,
  });

  const removeFile = async (value: Media) => {
    try {
      setIsLoading(true);
      await deleteAsset(value.url);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <motion.div
      initial="rest"
      whileHover="hover"
      animate="rest"
      className={`flex rounded-lg relative hover:shadow-lg w-full ${
        !disableReorder ? "h-[120px]" : ""
      }`}
      ref={selectImageVariant?.isLocal ? undefined : setNodeRef}
      style={{ ...style, zIndex: isDragging ? 9999 : 1 }}
    >
      <div className="relative z-[999] h-full">
        <motion.div
          variants={contextMenuMotion}
          className="bg-white/60 absolute top-0 left-0 opacity-0 h-full flex flex-col justify-between items-center p-1 "
        >
          {!disableReorder && (
            <div
              {...listeners}
              {...attributes}
              className="flex items-center gap-2"
            >
              <button className="flex items-center justify-center rounded border-none	bg-white p-2 text-gray-700 outline-none focus-visible:ring-2 focus-visible:ring-martEye-400 focus-visible:ring-offset-2  group-[.is-dragging]:bg-martEye-400 group-[.is-dragging]:text-martEye-100  cursor-grab  w-10">
                <svg
                  viewBox="0 0 20 20"
                  className="h-4 w-4 overflow-visible fill-current"
                >
                  <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                </svg>
              </button>
            </div>
          )}
          <MediaViewerWrapper media={value}>
            <Button
              variant="tertiary"
              type="button"
              title="Remove Media"
              isDisabled={isLoading}
              isLoading={isLoading}
              icon={<EyeIcon className="h-4 w-4" />}
            ></Button>
          </MediaViewerWrapper>

          <Button
            variant="danger"
            type="button"
            title="Remove Media"
            onClick={() => removeFile(value)}
            isDisabled={isLoading}
            isLoading={isLoading}
            icon={<XMarkIcon className="h-4 w-4" />}
          ></Button>
        </motion.div>
      </div>
      <div className="flex items-center gap-2 w-full relative">
        <MediaDisplay
          value={value}
          icon={
            displayMain && index === 0 ? (
              <StarIcon className="h-5 w-5" />
            ) : undefined
          }
        />
      </div>
      <div className="absolute bottom-0 left-0 w-full flex items-center justify-center  ">
        <span className="capitalize text-xs font-medium text-gray-900  w-full px-2">
          {value.uploadProgress?.state === "start" && (
            <progress value={0} max={1}></progress>
          )}
          {value.uploadProgress?.state === "progress" && (
            <progress
              value={value.uploadProgress.progress}
              max={value.uploadProgress.total}
            ></progress>
          )}
          {value.uploadProgress?.state === "end" && (
            <progress value={1} max={1}></progress>
          )}
        </span>
      </div>
      <div className="relative z-[999] h-full">
        <motion.div
          variants={contextMenuMotion}
          className=" absolute top-0 right-0 opacity-0  flex flex-col justify-between items-center p-1 rounded-lg "
        >
          <Button
            variant="tertiary"
            type="button"
            onClick={() => setMainImage?.(value)}
            title="Set as Main"
            isDisabled={isLoading}
            isLoading={isLoading}
            icon={<StarIconOutline className="h-4 w-4" />}
          ></Button>
        </motion.div>
      </div>
    </motion.div>
  );
};
